<template>
    <b-container v-if="survey" id="survey" class="p-4" fluid>
        <div
            v-if="!isTask"
            class="back"
            @click="goBackToSurveys"
        >
            <font-awesome-icon :icon="['fas','chevron-left']" />
            <span class="px-2">{{ $t('surveyRoute.infoText') }}</span>
        </div>
        <div class="survey-title">
            <div class="share-experience">{{ $t('surveyRoute.titleText') }}</div>
            <!-- <div class="share-subtext">{{ $t("surveyRoute.titleText") }}</div>-->
        </div>
        <b-row class="mt-3 mb-1">
            <b-col md="3">
                <div class="side-info">
                    <img
                        v-if="survey.imageUrl"
                        :src="survey.imageUrl"
                        alt=""
                        class="w-100"
                    >
                    <div class="instructions p-4">
                        <div class="survey-alias mb-3">{{ alias }}</div>
                        {{ help }}
                    </div>
                </div>
            </b-col>
            <survey-questions
                v-if="!isCompleted"
                :survey="survey"
                @update-survey="updateSurvey"
                @update-ratings="updateRatings"
            />
            <survey-carousel v-else :survey="survey" />
        </b-row>
        <div
            v-if="!isCompleted"
            class="buttons-container"
        >
            <div class="custom-col left">
                <button
                    v-if="isTask"
                    :class="{'highlight': clickedOnce}"
                    class="custom-button skip"
                    @click="skipSurvey"
                >{{ $t('surveyRoute.skipSurvey') }}
                </button>
                <small v-if="clickedOnce" class="skip-warning">{{ $t('surveyRoute.skipWarning') }}</small>
            </div>
            <div class="custom-col right">
                <button
                    class="custom-button submit"
                    @click="submit"
                >{{ $t('surveyRoute.buttonText') }}
                </button>
                <small v-if="submitHasBeenPrevented" class="submit-message">{{ $t('surveyRoute.errorMessage') }}</small>
            </div>
        </div>
    </b-container>
</template>

<script>
    import surveyService from '@/services/survey-service'

    import emitter from '@/utils/emitter'
    import SurveyQuestions from '@/components/SurveyQuestions'
    import SurveyCarousel from '@/components/SurveyCarousel'
    import taskUtil from '@/utils/task-util'

    export default {
        components: {
            SurveyQuestions,
            SurveyCarousel
        },

        data() {
            return {
                survey: null,
                ratings: [],
                submitHasBeenPrevented: false,
                clickedOnce: false
            }
        },
        computed: {
            isCompleted() {
                return this.survey.completed
            },
            mission() {
                return this.$store.getters.getMission(
                    parseInt(this.$route.params.missionId)
                )
            },
            task() {
                let missionId = parseInt(this.$route.params.missionId)
                let taskId = parseInt(this.$route.params.taskId)
                return this.$store.getters.getTask(missionId, taskId)
            },
            isTask() {
                return this.task && this.task.id
            },
            alias() {
                if (!this.survey.multilingualSurveys || !this.survey.multilingualSurveys[0]) {
                    return 'NO_TRANSLATION_ALIAS'
                }
                return this.survey.multilingualSurveys[0].config.alias
            },
            help() {
                if (!this.survey.multilingualSurveys || !this.survey.multilingualSurveys[0]) {
                    return 'NO_TRANSLATION_HELP'
                }
                return this.survey.multilingualSurveys[0].config.help
            }
        },
        async created() {
            const resp = await surveyService.getSurvey(this.$route.params.surveyId)

            this.survey = resp.data
            this.survey.surveyQuestions.sort(this.sortQuestions)

            this.handleRouteAsTask()
        },
        methods: {
            async handleRouteAsTask() {
                if (!this.task || !this.task.id) {
                    return
                }
                const missionId = parseInt(this.$route?.params?.missionId)
                const taskId = parseInt(this.$route?.params?.taskId)

                try {
                    await this.$store.dispatch('startTask', { missionId, taskId })
                } catch (error) {
                    this.$router.push({ path: '/missions/' + missionId, replace: true })
                }
            },
            goBackToSurveys() {
                this.$router.push('/surveys')
            },
            submit() {
                if (!(this.ratings.length === this.survey.surveyQuestions.length)) {
                    this.submitHasBeenPrevented = true
                    return
                }

                let surveyId = this.$route.params.surveyId
                surveyService.submitSurvey(surveyId, this.ratings).then(() => {
                    surveyService.getSurvey(surveyId).then((res) => {
                        this.survey = res.data
                    })
                })
                if (this.isTask) {
                    let points = this.task.points
                    let finished = true
                    taskUtil.updateTask(this.mission, this.task, points, finished, this.$router)
                } else {
                    emitter.emit('openSurveySubmitModal', {
                        surveyAlias: this.alias
                    })
                }
            },
            skipSurvey() {
                if (!this.clickedOnce) {
                    this.clickedOnce = true
                    return
                }
                let points = 0
                let finished = true
                taskUtil.updateTask(this.mission, this.task, points, finished, this.$router)
            },
            sortQuestions(a, b) {
                return a.position - b.position
            },
            updateSurvey(survey) {
                this.survey = { ...survey }
            },
            updateRatings(ratings) {
                this.submitHasBeenPrevented = false
                const ratingsArray = Object.entries(ratings)
                this.ratings = ratingsArray.map(x => ({ questionId: x[0], rating: x[1] }))
            }
        }
    }
</script>

<style lang="scss" scoped>
.img-box img {
    width: 35px;
}

.img-box p {
    text-align: center;
}

.survey-logo {
    width: 25px;
}

.alias-style {
    color: var(--secondary-color);
    font-size: 27px;
    padding-top: 20px;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
}

.cursor-helper {
    cursor: pointer;
}

.total-votes {
    color: var(--secondary-color);
    font-weight: 700;
}

.opacity-background {
    background-color: rgb(0 0 0 / 40%);
}

.carousel-inner {
    height: 400px;
}

.question-title {
    font-weight: 700;
    font-size: 16px;
}

.carousel-icons {
    width: 25px;
}

.smile-labels-icon {
    width: 100%;
    align-items: center;
    justify-content: start;
}

.smile-labels {
    font-size: 15px;
    text-align: left;
}

.vertical-align {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.survey-text {
    font-weight: 700;
    text-align: left;
}

.back {
    display: flex;
    align-items: center;
    color: var(--main-content-text);
    text-align: left;
    cursor: pointer;
}

.back span {
    font-size: 85%;
}

.back svg {
    color: var(--main-content-text);
}

.survey-title {
    text-align: left;
}

.survey-title .share-experience {
    font-weight: bold;
    font-size: 150%;
    color: var(--main-content-text);
}

.survey-title .share-subtext {
    font-size: 80%;
    color: var(--main-content-text);
}

.side-info {
    text-align: left;
}

.side-info .instructions {
    background-color: var(--white);
    font-size: 85%;
}

.side-info .instructions .survey-alias {
    font-weight: bold;
    font-size: 125%;
}

.question-container {
    background: rgb(0 0 0 / 30%);
    color: var(--white);
}

.buttons-container {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}

.custom-col {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.custom-col.left {
    align-items: flex-start;
}

.custom-col.right {
    align-items: flex-end;
}

.custom-button {
    height: 40px;
    width: 100%;
    max-width: 140px;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 1px;
    box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
    border-radius: 4px;
    border: 1px solid transparent;
    background: rgb(0 0 0 / 30%);
    transition: all 0.2s ease-in-out;
    color: var(--white);
    outline: none;
}

.custom-button.skip {
    background: transparent;
    border: 1px solid var(--disabled-color);
    color: var(--disabled-color);
}

.custom-button.submit {
    background: var(--secondary-color);
    color: var(--white);
}

.skip-warning {
    color: var(--disabled-color);
}

.submit-message {
    color: var(--white);
}

.progress {
    background: var(--black);
}

.progress .color_0 {
    background: var(--survey-very-poor);
}

.progress .color_1 {
    background: var(--survey-poor);
}

.progress .color_2 {
    background: var(--survey-acceptable);
}

.progress .color_3 {
    background: var(--survey-very-good);
}

.progress .color_4 {
    background: var(--survey-excellent);
}

@media screen and (min-width: 500px) {
    .custom-col {
        width: 130px;
        gap: 1rem;
    }

    .custom-button {
        width: 130px;
        height: 45px;
    }
}

@media screen and (min-width: 768px) {
    .buttons-container {
        padding-left: 0.5rem;
        margin-left: auto;
        width: 75%;
    }

    .custom-col.left {
        align-items: flex-start;
        text-align: left;
        width: 60%;
    }

    .custom-col.right {
        align-items: flex-end;
        text-align: right;
        width: 40%;
    }

    .custom-button {
        width: 160px;
    }
}
</style>
